export const enum NonprofitActionTypes {
  NONPROFITSEARCH_REQUEST = '@@nonprofit/NONPROFITSEARCH_REQUEST',
  HISTORY_REQUEST = '@@nonprofit/HISTORY_REQUEST',
  NONPROFITRESULTS_REQUEST = '@@nonprofit/NONPROFITRESULTS_REQUEST',
  NONPROFIT_REQUEST = '@@nonprofit/NONPROFIT_REQUEST',
  HISTORY_SUCCESS = '@@nonprofit/HISTORY_SUCCESS',
  AGENT_ERROR_SUCCESS = '@@nonprofit/AGENT_ERROR_SUCCESS',
  NONPROFIT_SUCCESS = '@@nonprofit/NONPROFIT_SUCCESS',
  NONPROFITRESULTS_SUCCESS = '@@nonprofit/NONPROFITRESULTS_SUCCESS',
  SET_NONPROFITID = '@@nonprofit/SET_NONPROFITID',
  SET_CURRENT_NONPROFIT = '@@nonprofit/SET_CURRENT_NONPROFIT',
  SET_ADDITIONALNONPROFITINFO = '@@nonprofit/SET_ADDITIONALNONPROFITINFO',
  RESET_STATE = '@@nonprofit/RESET_STATE',
  FETCH_ERROR = '@@nonprofit/FETCH_ERROR',
  FETCH_NONPROFIT_ERROR = '@@nonprofit/FETCH_NONPROFIT_ERROR',
  FETCH_NONPROFITSUMMARY_ERROR = '@@nonprofit/FETCH_NONPROFITSUMMARY_ERROR'
}

export enum NonprofitDocumentEnum {
  HISTORY = 'history',
  DOCUMENTS = 'document',
  ESCALATIONS = 'escalations'
}

export interface IAdditionalData {
  tenants: any[];
  domains: any[];
  transactionIds: any[];
  agentEmail: string;
  techSoupOrgId: string;
  masterNonprofitId: string;
  nonprofitName: string;
  status: string;
  effectiveDateTime: string;
  activityCode: string;
  eligibilityProvider: string;
}

export interface INonprofitState {
  nonprofitSearchResults: INonProfitSearchResult | undefined;
  nonprofitDetail: INonprofitDetail | undefined;
  historyResults: IHistoryResult | undefined;
  agentErrorResults: IAgentErrorSummaryResult | undefined;
  isAgentErrorLoading: boolean;
  nonprofitId: string;
  isNonprofitSearchResultsLoading?: boolean;
  isNonprofitDetailLoading?: boolean;
  isHistoryResultsLoading?: boolean;
  additionalNonprofitInfo?: IAdditionalData;
}

export interface INonprofit {
  id: string;
  agentEmail: string;
  effectiveDateTime: string;
  nonprofitAddressCity: string;
  nonprofitAddressCountryCode: string;
  nonprofitAddressStateRegion: string;
  nonprofitId: string;
  nonprofitName: string;
  status: string;
  techSoupName: string;
  techSoupOrgId: string;
  officeProfiles: IOfficeProfiles[];
  transactions: ITransactions[];
}

export interface IOfficeProfiles {
  domain: string;
  tagValue: string;
  tenantId: string;
}

export interface ITransactions {
  correlationId: string;
  transactionId: string;
}

export interface INonProfitSearchResult {
  NonprofitDocument: INonprofitDocument[];
  TechSoupProfileDocument: ITechSoupProfileDocument[];
  TechSoupTransactionDocument: ITechSoupTransactionDocument[];
  AdminActionDocument: IAdminActionDocument[];
  OfficeProfileDocument: IOfficeProfileDocument[];
  RegistrationIdentifierDocument: IRegistrationIdentifierDocument[];
  AzureRegistrationDocument: IAzureRegistrationDocument[];
  TenantDetailsDocument: ITenantDetailsDocument[];
  AdsRegistrationDocument: IAdsRegistrationDocument[];
  TradeScreenDocument: ITradeScreenDocument[];
  GoodstackTransactionDocument: IGoodstackTransactionDocument[];
}

interface IAzureRegistrationDocument {
  documentType: string;
  companyName: string;
  accountOwnerLiveId: string;
  registrationId: string;
  registrationNumber: string;
  startDate: string;
  endDate: string;
  monetaryCap: string;
  notes: string;
  nonprofitId: string;
  comments: string;
  statusId: string;
  renewalEmailStatus: string;
  renewalEmailSent: string;
  accountOwnerLiveIdMatch: boolean;
  companyNameMatch: boolean;
  noNonProfitId: boolean;
  nonProfitCompanyNameMatch: boolean;
  lastModifiedOn: string;
  insertedOn: string;
  approvedOn: string;
  potentialNonprofitIdMatches: any[];
  foreignPartnerProgramId: string;
}

interface IRegistrationIdentifierDocument {
  documentType: string;
  nonprofitId: string;
  legalIdentifier: string;
  regulatoryBody: string;
  artifactUri?: string;
  source: string;
  id: string;
  _ts: number;
  createdDate: string;
  createdBy: string;
}

interface IOfficeProfileDocument {
  documentType: string;
  nonprofitId: string;
  tenantId: string;
  domain: string;
  adminEmail: string;
  adminPuid: string;
  adminUsername: string;
  tagValue: string;
  id: string;
  _ts: number;
  createdDate: string;
  createdBy: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
}

interface IAdminActionDocument {
  documentType: string;
  nonprofitId: string;
  requestId: string;
  correlationId: string;
  reason: string;
  status: string;
  processName: string;
  escalationCode: string;
  escalationType: string;
  escalationDateTime: string;
  id: string;
  _ts: number;
  createdDate: string;
  createdBy: string;
}

interface ITechSoupTransactionDocument {
  documentType: string;
  nonprofitId: string;
  transactionId: string;
  correlationId: string;
  qualification: string;
  transactionPhase: string;
  transaction: string;
  isClosed: boolean;
  retries: number;
  id: string;
  _ts: number;
  createdDate: string;
  createdBy: string;
}

export interface IGoodstackTransactionDocument {
  documentType: string;
  nonprofitId: string;
  transactionId: string;
  status: string | null;
  orgName?: string | null;
  orgRegistryName?: string | null;
  orgRegistryId?: string;
  failureReasons?: IFailureReason[] | null;
  // validationStatus?: string | null;
  // eligibilityStatus?: string | null;
  // complianceStatus?: string | null;
  complianceTags?: string[] | null;
  noncomplianceTags?: string[] | null;
  confirmedActivityTags?: string[] | null;
  rejectedActivityTags?: string[] | null;
  metadata?: any;
  id: string;
  _ts: number;
  createdDate: string;
  createdBy: string;
}
export interface IFailureReason {
  check: string;
  reason: string;
}

interface ITechSoupProfileDocument {
  documentType: string;
  nonprofitId: string;
  techSoupOrgId: string;
  name: string;
  missionStatement: string;
  email: string;
  phone: string;
  website: string;
  addressLine1: string;
  addressOther: string;
  addressCity: string;
  addressPostalCode: string;
  addressCountryCode: string;
  isEligible: boolean;
  lastValidationDate: string;
  errorCodes: any[];
  id: string;
  _ts: number;
  createdDate: string;
  createdBy: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
}

interface INonprofitDocument {
  documentType: string;
  name: string;
  missionStatement: string;
  operatingBudget: string;
  activityCode: string;
  email: string;
  emailLanguagePreference: string;
  phone: string;
  website: string;
  addressLine1: string;
  addressOther: string;
  addressCity: string;
  addressStateRegion: string;
  addressPostalCode: string;
  addressCountryCode: string;
  schema: string;
  status: string;
  agentFirstName: string;
  agentLastName: string;
  agentEmail: string;
  partnerEmailEnabled: boolean;
  effectiveDateTime: string;
  lastValidationDate: string;
  id: string;
  _ts: number;
  createdDate: string;
  createdBy: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
  eligibilityProvider: string;
}

interface ITenantDetailsDocument {
  documentType: string;
  managedSegment: string;
  topParentOrgId: string;
  orgId: string;
  assignedSeats: number;
  activeUsers: number;
  inactiveUsers: number;
  usage: number;
  e1Seats: number;
  e3Seats: number;
  e5Seats: number;
  m365BasicGrant: number;
  m365PremiumGrant: number;
  tenantStatus: string;
  cloudForNonprofitBasic: number;
  cloudForNonprofitStandard: number;
  tenantType: string;
  nonprofitId: string;
  id: string;
  _ts: number;
  createdDate: string;
  createdBy: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
}

interface IAdsRegistrationDocument {
  documentType: string;
  nonprofitId: string;
  status: string;
  provider: string;
  website: string;
  countryCode: string;
  language: string;
  currencyCode: string;
  accountId: string;
  accountNumber: string;
  customerId: string;
  customerNumber: string;
  createdDate: string;
  createdBy: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
}

interface ITradeScreenDocument {
  documentType: string;
  nonprofitId: string;
  status: string;
  reasonCode: string;
  reasonDescription: string;
  dataIssueCode: string;
  lastProfileUpdatedDate: string;
  lastTradeScreenedDate: string;
  createdDate: string;
  createdBy: string;
  lastUpdatedDate: string;
  lastUpdatedBy: string;
}

export interface INonprofitDetail {
  id: string;
  name: string;
  tenantId: string;
  domain: string;
  legalIdentifier: string;
  status: string;
  websiteUrl: string;
  isCharityApproved: boolean;
  address: IAddress;
  effectiveDateTime: string;
  masterNonprofitId: string;
}

interface IAddress {
  addressLine1: string;
  addressOther: string;
  city: string;
  stateRegion: string;
  countryCode: string;
  country: string;
  postalCode: string;
  phone: string;
}

export interface IHistoryResult {
  HistoryDocument: IHistoryDocument[];
  TechSoupHistoryDocument: ITechSoupHistoryDocument[];
  GoodstackHistoryDocument: IGoodstackHistoryDocument[];
  EmailServiceHistoryDocument: IEmailServiceHistoryDocument[];
}

export interface IAgentErrorSummaryResult {
  count: number;
  lastErrorDateTime: string;
}
export interface IEmailServiceHistoryDocument {
  documentType: string;
  emailType: string;
  emailName: string;
  to: string;
  subject: string;
  id: string;
  timestamp: string;
  createdDate: string;
  createdBy: string;
  statusDescription: string;
  correlationId: string;
  entityId: string;
  entityIdType: string;
  action: string;
  status: string;
  description: string;
  from?: string;
}

interface ITechSoupHistoryDocument {
  documentType: string;
  transactionId: string;
  transaction: string;
  transactionPhase: string;
  qualification: string;
  isClosed: boolean;
  id: string;
  timestamp: string;
  createdDate: string;
  createdBy: string;
  statusDescription: string;
  correlationId: string;
  entityId: string;
  entityIdType: string;
  action: string;
  status: string;
  description: string;
}

export interface IGoodstackHistoryDocument {
  documentType: string;
  id: string;
  timestamp: string;
  createdDate: string;
  createdBy: string;
  entityId: string;
  entityIdType: string;
  action: string;
  status: string;
  statusDescription: string;
  description?: string;
  transactionId: string;
  transactionStatus: string;
  organisation: object;
  validationRequest: object;
  agentVerification: object | null;
  monitoringSubscription: object;
  eligibilitySubscription: object;
  failureReasons: object;
  metadata: object;
}

interface IHistoryDocument {
  documentType: string;
  id: string;
  timestamp: string;
  createdDate: string;
  createdBy: string;
  statusDescription: string;
  correlationId: string;
  entityId: string;
  entityIdType: string;
  action: string;
  status: string;
  description?: string;
}
